<template>
    <el-row class="Condition" type="flex" :gutter="8">
        <el-col :span="4">
            <el-select size="small" v-model="relation" class="relation">
                <el-option label="AND" value="and"></el-option>
                <el-option label="OR" value="or"></el-option>
                <el-option label="NOT" value="not"></el-option>
            </el-select>
        </el-col>
        <el-col :span="6">
            <el-select size="small" v-model="type" class="type">
                <el-option label="文件名" value="name"></el-option>
                <el-option label="存储类型" value="storageClass"></el-option>
                <el-option label="标签名" value="tagging"></el-option>
                <el-option label="存储桶名" value="bucketName" v-if="isUserlevelSearch"></el-option>
            </el-select>
        </el-col>
        <el-col :span="index > 1 ? 12 : 14">
            <el-input size="small" placeholder="请输入" v-model="value" v-if="type != 'storageClass'" class="text">
            </el-input>
            <el-select v-else size="small" v-model="value" multiple :collapse-tags="true" class="storage">
                <el-option v-show="storageClassList.indexOf('STANDARD') > -1" label="标准存储" value="STANDARD"></el-option>
                <el-option v-show="storageClassList.indexOf('INTELLIGENT') > -1" label="智能存储" value="INTELLIGENT"></el-option>
                <el-option v-show="storageClassList.indexOf('ARCHIVE') > -1" label="归档存储" value="ARCHIVE"></el-option>
                <el-option v-show="storageClassList.indexOf('GLACIER') > -1" label="深度归档存储" value="GLACIER"></el-option>
            </el-select>
        </el-col>
        <el-col :span="2" v-if="index > 1">

<!--            <i class="el-icon-delete delCondition" @click="handleClickRemove"/>-->
                  <div class="delCondition" @click="handleClickRemove">
                      <i class="el-icon-delete" />
                  </div>
        </el-col>
    </el-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'Condition',
    props: {
        index: {
            type: Number,
            default: 0,
        },
    },
    components: {},
    data() {
        return {
            relation: 'and',
            type: 'name',
            value: '',
        }
    },
    computed: {
        ...mapGetters(['storageClassList']),
        isUserlevelSearch() {
            return this.$route.name == 'userlevelSearch'
        },
    },
    filters: {},
    watch: {
        type() {
            this.value = ''
            this.handleUpdate()
        },
        relation() {
            this.handleUpdate()
        },
        value() {
            this.handleUpdate()
        },
    },
    methods: {
        handleClickRemove() {
            this.$emit('remove', this.index)
        },
        handleUpdate() {
            this.$emit('update', this.index, {
                relation: this.relation,
                type: this.type,
                value: this.value,
            })
        },
        reset() {
            this.relation = 'and'
            this.type = 'name'
            this.value = ''
        },
    },
}
</script>

<style lang='scss' scoped>
.Condition {
    width: 50%;
    margin-bottom: 10px;

    .storage {
        width: 100%;
    }

    .delCondition {
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 20px;
        cursor: pointer;
    }
}
</style>
