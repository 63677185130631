<template>
    <div class="userlevelSearch page-container">
        <el-breadcrumb class="crumb" separator="/">
            <el-breadcrumb-item>{{ isUserlevelSearch ? '用户级搜索' : '高级搜索' }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="condition">
            <div class="list">
                <el-row class="item" type="flex" :gutter="8">
                    <el-col :span="4">
                        <el-select size="small" v-model="mtime.relation" class="relation">
                            <el-option label="AND" value="and"></el-option>
                            <el-option label="OR" value="or"></el-option>
                            <el-option label="NOT" value="not"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="6">
                        <label style="height: 30px;line-height: 30px;text-align: left;">对象更新时间</label>
                    </el-col>
                    <el-col :span="14">
                        <el-date-picker size="small" v-model="mtime.text" type="daterange" range-separator="至"
                                        start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
                                        value-format="yyyy-MM-dd"
                                        :clearable="false">
                        </el-date-picker>
                    </el-col>
                </el-row>
                <el-row class="item" type="flex" :gutter="8">
                    <el-col :span="4">
                        <el-select size="small" v-model="size.relation" class="relation">
                            <el-option label="AND" value="and"></el-option>
                            <el-option label="OR" value="or"></el-option>
                            <el-option label="NOT" value="not"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="6">
                        <label style="height: 30px;line-height: 30px;text-align: left;">文件大小</label>
                    </el-col>
                    <el-col :span="14">
                        <el-row>
                            <el-col :span="11">
                                <storage-input v-model="size.start" size="small"/>
                            </el-col>
                            <el-col :span="2"><span class="txt">至</span></el-col>
                            <el-col :span="11">
                                <storage-input v-model="size.end" size="small"/>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
            <div class="list">
                <template v-for="(item, index) in conditions">
                    <condition ref="condition" @update="modifyCondition" @remove="delCondition" :index="index"
                               :key="index"/>
                </template>
            </div>
            <div class="operate">
                <el-button size="small" type="primary" @click="addCondition()">添加条件</el-button>
                <el-button size="small" type="primary" @click="search()">搜索文件</el-button>
                <el-button size="small" @click="resetCondition()">重置条件</el-button>
            </div>
        </div>
        <div class="operate">
            <el-button size="small" @click="$router.back()" v-if="!isUserlevelSearch">退出搜索</el-button>
            <el-button v-if="hasAuth" size="small" @click="delFiles()" type="danger" :disabled="selectData.length === 0">
                删除文件
                <el-tooltip effect="dark" content="删除操作有延迟，请耐心等待" placement="top">
                    <i class="el-icon-info"></i>
                </el-tooltip>
            </el-button>
            <el-button v-if="hasAuth" size="small" @click="addTag()" :disabled="selectData.length === 0">添加标签</el-button>
<!--            <el-button size="small" @click="recovery()" :disabled="selectData.length === 0">数据恢复</el-button>-->
            <el-button size="small" @click="refresh()">刷新</el-button>
        </div>
        <div class="tableBox">
            <el-table :data="tableData" :row-key="getRowKey" @selection-change="handleSelectionChange"
                      v-loading="loading" ref="table">
                <el-table-column type="selection" width="45" align="center">
                </el-table-column>
                <el-table-column prop="tag" label="序号" align="center" width="55">
                    <template slot-scope="scope">
                        {{ scope.$index + (pager.current - 1) * 10 + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="fileName" label="文件名称" max-width="300px" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div class="fileName" :title="scope.row.fileName" @click="lookDetail(scope.row)">
                            <div>{{ handleFileName(scope.row.name)[1] }}</div>
                            <!--                            <span><i class="iconfont iconbiaoqian"></i>{{ scope.row.tagCount.length }}</span>-->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="fileRoad" label="文件路径" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ handleFileName(scope.row.name)[0] || '/' }}
                    </template>
                </el-table-column>
                <el-table-column prop="bucket" label="存储桶" :show-overflow-tooltip="true" v-if="isUserlevelSearch"
                                 width="120"></el-table-column>
                <el-table-column label="存储类型" align="center" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.storageClass == 'STANDARD'">标准存储</span>
                        <span v-else-if="scope.row.storageClass == 'ARCHIVE'">归档存储</span>
                        <span v-else-if="scope.row.storageClass == 'INTELLIGENT'">智能存储</span>
                        <span v-else-if="scope.row.storageClass == 'GLACIER'">深度归档</span>
                        <!--                        <span v-else-if="scope.row.storageClass == '4'">智能存储<el-tooltip effect="dark" :content=scope.row.tips-->
                        <!--                                                                                placement="top">-->
                        <!--                <i class="el-icon-info"></i>-->
                        <!--              </el-tooltip></span>-->
                    </template>
                </el-table-column>
                <el-table-column prop="size" label="文件大小" align="center" width="120">
                    <template slot-scope="scope">{{ bytesToSize(scope.row.size) }}</template>
                </el-table-column>
                <el-table-column prop="mtime" label="更新时间" align="center" width="200px">
                    <template slot-scope="scope">{{ moment(scope.row.mtime).format('YYYY-MM-DD HH:mm:ss') }}</template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="200px" fixed="right">
                    <template slot-scope="scope">
                        <div class="file-table-action-list" style="margin-left: -6px;">
                            <el-button size="small" type="text" @click="restore(scope.row)"
                                       v-if="scope.row.filestate == '1'">取回
                            </el-button>
                            <el-button size="small" type="text" @click="downloadFile(scope.$index, scope.row)"
                                       v-else-if="scope.row.filestate == '3' ">下载
                            </el-button>
                            <el-button size="small" type="text" v-else-if="scope.row.filestate == '2'">取回中</el-button>
                            <el-button size="small" type="text" v-else-if="scope.row.filestate == '4'">
                                <el-tooltip effect="dark" content="盘匣已离线，文件无法取回，请查看文件详情获取文件位置" placement="top-start">
                                    <span class="status">已离线</span>
                                </el-tooltip>
                            </el-button>
                            <el-button size="small" v-if="hasAuth" type="text" @click="editTag(scope.row)">编辑标签</el-button>

                            <el-button
                                v-if="isShowDeleteBtn(scope.row) && hasAuth"
                                size="small" type="text" @click="handleDelete(scope.row)">删除</el-button>
                            <el-tooltip
                                v-if="!isShowDeleteBtn(scope.row) && hasAuth"
                                placement="top"
                                trigger="hover"
                                popper-class="pop12"
                            >
                                <div slot="content">智能存储、深度归档类型不支持删除</div>
                                <span style="cursor: not-allowed;color: #acacac;"> 删除 </span>
<!--                                <el-button size="small" type="text" :disabled="true" > 删除 </el-button>-->
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <Pager class="bs-pager" v-model="pager" :sizes="[5, 10, 20, 50, 100]"
                   @currentChange="handleCurrentChange" @sizeChange="handleSizeChange"></Pager>
        </div>
        <!-- 文件详情 -->
        <!--        <el-drawer :visible.sync="drawer" @close="closeDrawer()" :modal="false">-->
        <!--            <file-detail v-if="isDetail" :bucketType="thisBucketType" :bucketName="fileInfo.bucketName"-->
        <!--                         :fileDetail_name="fileDetail_name" :path="fileInfo.path">-->
        <!--            </file-detail>-->
        <!--        </el-drawer>-->
<!--        <add-tag :fileInfo="selectData" @close="isAddTag = false" @sure="afresh()" v-if="isAddTag"></add-tag>-->
        <add-tag ref="addTagRef" @complete="afresh()"/>
        <edit-tag ref="editTagRef"/>

        <FileDetail ref="fileDetailRef" />
        <!--        <confirm-recover-files ref="confirmRecoverFiles"/>-->
        <!--        <takeback-setting ref="takebackSetting"/>-->
    </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'
// import {objEncrypt} from '@/assets/js/utils'
import storage from '../utils/storage'
import {set, get} from '../utils/storage'
import addTag from './bucket/components/addTag'
import editTag from './bucket/components/editTag'
import FileDetail from "./bucket/components/fileDetail";
// import fileDetail from '@/pages/objectStorage/page/fileDetail'
import Condition from './components/Condition.vue'
import StorageInput from './components/StorageInput.vue'
import {addMeta} from "@/utils/session";
// import ConfirmRecoverFiles from '@/components/sync/ConfirmRecoverFiles'
// import TakebackSetting from '@/components/objectStorage/TakebackSetting'
import moment from 'moment'
import { bytesToSize } from "@/utils";

export default {
    name: 'userlevelSearch',
    components: {
        Condition,
        // fileDetail,
        addTag,
        editTag,
        StorageInput,
        FileDetail
        // ConfirmRecoverFiles,
        // TakebackSetting,
    },
    data() {
        return {
            conditions: [{}, {}],
            mtime: {
                relation: 'and',
                type: 'mtime',
                text: '',
            },
            size: {
                relation: 'and',
                type: 'size',
                start: 0,
                end: 0,
            },
            searchCondition: {},
            pickerOptions: {
                disabledDate(time) {
                    return Date.now() <= time.getTime() // 如果没有后面的-8.64e7就是不可以选择今天的
                },
            },
            tableData: [],
            selectData: [],
            pager: {
                current: 1,
                size: 10,
                total: 0,
            },
            marker: 1,
            isRefresh: false,
            isSearch: false,
            isDetail: false,
            isAddTag: false,
            isEditTag: false,
            fileInfo: {},
            quickClick: false,
            thisBucketType: '1',
            fileName: [],
            fileStatus: [],
            timer: null,
            fileDetail_name: '',
            drawer: false,

            selectItem: '', // 取回操作回写数据使用
            loading: false,
            regionList: [],
            regionId: '',
            bucket: '',
            bucketAuth: 'READ_AND_WRITE'
        }
    },
    computed: {
        // ...mapState('userlevelSearch', ['loading']),
        showData() {
            return this.tableData
        },
        isUserlevelSearch() {
            return this.$route.name == 'userlevelSearch'
        },
        hasAuth () {
            return this.$route.name === 'userlevelSearch' || this.bucketAuth === 'READ_AND_WRITE'
        }
    },
    beforeRouteLeave(to, from, next) {
        this.resetCondition()
        next()
    },
    beforeDestroy() {
        this.timer && clearInterval(this.timer)
    },
    mounted() {
        this.getRegionList()
        if (!this.isUserlevelSearch) {
            this.bucket = this.$route.query.bucket || ''
            this.bucketAuth = this.$route.query.bucketAuth
        }
    },
    watch: {
        $route: {
            handler: function (val, oldVal) {
                this.tableData = []
                this.pager.total = 0
                this.pager.current = 1
            },
            deep: true,
        },
    },
    methods: {
        bytesToSize,
        moment,
        isShowDeleteBtn ({meta, storageClass}) {
            return storageClass === 'STANDARD'
            // return storageClass === 'STANDARD' || (storageClass === 'INTELLIGENT' && meta.lastModified && this.moment(meta.lastModified).add(90, 'd')>this.moment())
        },
        handleFileName(name = '') {
            let i = name.lastIndexOf('/')
            if (i > -1) {
                return [name.substring(0, i), name.slice(i + 1)]
            } else {
                return ['', name]
            }
        },
        getRegionList() {

            this.$post('/region/listPurchased', {})
                .then(res => {
                    if (res.code === '200') {
                        this.regionList = res.data || [];
                        if (!this.regionId) {
                            this.regionId = this.regionList[0]?.regionId
                        }
                    }
                })
        },
        // ...mapActions('userlevelSearch', ['getFile', 'deleteFiles', 'downloadFile', 'searchBucket', 'getJudgeRestore']),
        changeType(item) {
            if (item.type == 'storageClass') {
                item.storage = []
            } else {
                item.text = ''
            }
        },
        allChoice(item) {
            item.storage = ['STANDARD', 'ARCHIVE', 'GLACIER']
        },
        cancelChoice(item) {
            item.storage = []
        },
        addCondition() {
            if (this.conditions.length >= 8) {
                this.$message.error('至多添加六个搜索条件')
            } else {
                this.conditions.push({})
            }
        },
        modifyCondition(index, condition) {
            this.conditions[index] = condition
        },
        delCondition(index) {
            this.conditions.splice(index, 1)
        },
        resetCondition() {
            this.$refs['condition'].forEach((c) => c.reset())
            this.conditions = [{}, {}]
            this.mtime = {
                relation: 'and',
                type: 'mtime',
                text: '',
            }
            this.size = {
                relation: 'and',
                type: 'size',
                start: 0,
                end: 0,
            }
        },
        search() {
            console.log('size: ',this.size)
            this.searchCondition = {
                and: [],
                not: [],
                or: [],
            }
            if (!this.isUserlevelSearch) {
                this.searchCondition.and.push({bucketName: this.bucket})
                this.searchCondition.bucketName = this.bucket
            }
            this.conditions.forEach((item) => {
                if (!!item.value && item.value != '') {
                    this.searchCondition[item.relation].push({
                        [item.type]: item.value,
                    })
                }
            })
            if (this.mtime.text.length == 2) {
                this.searchCondition[this.mtime.relation].push({
                    mtime: this.mtime.text,
                })
            }
            if (this.size.start !== 0 || this.size.end !== 0) {
                if (Number(this.size.start) > Number(this.size.end)) {
                    this.$message.error('文件大小区间选择有误')
                    return
                } else {
                    this.searchCondition[this.size.relation].push({
                        size: [this.size.start, this.size.end],
                    })
                }
            } else if ((this.size.start === '' && this.size.end !== '') || (this.size.start !== '' && this.size.end === '')) {
                this.$message.error('文件大小区间选择有误')
                return
            }
            this.afresh()
        },
        getList(ignore = []) {
            this.quickClick = true
            const {and, or, not} = this.searchCondition
            let data = {
                andList: and,
                orList: or,
                notList: not,
                pageNum: this.pager.current,
                pageSize: this.pager.size,
                // regionId: this.regionId
            }
            if (!this.isUserlevelSearch) {
                data.inBucket = true
            }
            this.$postJson('/es/search', data)
                .then(res => {
                    if (res.code === '200') {
                        this.tableData = (res.data.fileList || []).map(item => ({...item, key: item.name, displayName: this.handleFileName(item.name)[1]}))
                        this.pager.total = res.data.count || 0
                        this.isSearch = true
                        this.handleMeta(ignore)
                    }
                })
                // this[this.isUserlevelSearch ? 'getFile' : 'searchBucket']({
                //     ...this.searchCondition,
                //     marker: this.marker,
                // })
                //     .then((res) => {
                //         if (this.isRefresh) {
                //             this.$message.success('列表刷新成功')
                //         }
                //         this.isSearch = true
                //         this.tableData = this.tableData.slice(0, (this.page - 1) * 50).concat(res.data.data)
                //         this.total = this.tableData.length
                //         if (res.data.marker != '?') {
                //             this.marker = res.data.marker
                //             this.total++
                //         }
                //     })
                .finally(() => {
                    this.isRefresh = false
                    this.quickClick = false
                })
        },
        handleMeta(ignore = []) {
            this.tableData.forEach((item, index) => {
                if (ignore.indexOf(item.bucket + item.name) === -1) {
                    const data = {bucketName: item.bucket, key: item.name, regionId: this.regionId};
                    this.$postJson("/file/meta", data).then((res) => {
                        this.$set(this.tableData[index], "filestate", res.data.status);
                        this.$set(this.tableData[index], "status", res.data.status);
                        this.$set(this.tableData[index], "meta", res.data.meta);
                    });
                }
            })

        },
        getRowKey(row) {
            return row.bucketName + row.name + moment(row.mtime).valueOf()
        },
        afresh(ignore = []) {
            if (!this.quickClick) {
                this.tableData = []
                this.pager.total = 0
                this.pager.current = 1
                this.marker = 1
                this.getList(ignore)
            }
        },
        handleCurrentChange(val) {
            // if (val == 1) {
            //     this.afresh()
            // } else if (val % 5 == 1 && val * 50 > this.tableData.length) {
            //     this.getList()
            // }
            this.pager.current = val
            this.getList()

            // this.page = val
        },
        handleSelectionChange(val) {
            this.selectData = val
        },
        addTag() {
            if (this.selectData.length == 0) {
                this.$message.error('请至少选择一个文件')
                return
            }
            this.$refs.addTagRef.show(this.selectData.map(i => {
                return {
                    ...i,
                    bucketName: this.handleFileName(i.name)[0],
                    key: this.handleFileName(i.name)[1],
                }
            }), '',)
        },
        handleDelete (row) {
            this.delFilesSubmit([row])
        },
        delFiles() {
            if (this.selectData.length == 0) {
                this.$message.error('请至少选择一个文件')
                return
            }

            // let list1 = this.selectData.filter(item => {
            //     return item.storageClass === 'ARCHIVE' || item.storageClass === 'GLACIER'
            //         // || (item.storageClass === 'INTELLIGENT' && item.meta.lastModified && this.moment(item.meta.lastModified).add(90, 'd')>this.moment()))
            // })
            //
            // if (list1.length > 0) {
            //     this.$message.warning('深度归档类型不支持删除：' + list1[0].displayName)
            //     return;
            // }

            // let list2 = this.selectData.filter(item => {
            //     return  (item.storageClass === 'INTELLIGENT' && item.meta.lastModified && this.moment(item.meta.lastModified).add(90, 'd')< this.moment())
            // })
            //
            // if (list2.length > 0) {
            //     this.$message.warning('智能存储文件已上传超过90天，不支持删除：'+ list2[0].displayName)
            //     return;
            // }


           this.delFilesSubmit(this.selectData)
        },
        delFilesSubmit(selectData) {
            let bucketKeyList = selectData.map(item => {
                return {
                    bucketName: item.bucket,
                    key: item.name
                }
            })
            let data = {
                regionId: this.regionId,
                bucketKeyList
            }
            this.$confirm(`文件删除后无法恢复，确认删除文件吗?`, '删除文件', {
                type: 'warning',
            }).then((res) => {
                this.$postJson('/file/batchDeleteDifBucket', data)
                    .then((res) => {
                        if (res.code === '200') {
                            if (!res.data || res.data.length === 0) {
                                this.$message.success('删除文件成功')
                            } else {
                                this.$alert(res.data, '提示')
                            }
                            setTimeout(() => {
                                // this.afresh(bucketKeyList.map(item => `${item.bucketName}${item.key}`))
                                typeof this !== 'undefined' && (this.afresh())
                            },5000)
                        }
                    })
            })
        },




        refresh() {
            if (!this.isSearch) {
                this.$message.error('请先进行搜索')
                return
            }
            this.isRefresh = true
            this.pager.current = 1
            this.afresh()
        },
        lookDetail(item) {
            this.$refs.fileDetailRef.show({...item, bucketName: item.bucket})
            // this.fileInfo = item
            // this.fileDetail_name = item.fileName
            // this.fileInfo.path = item.fileRoad.slice(1)
            // if (this.$route.name == 'userlevelSearch') {a
            //     if (item.bucketEx == 0) {
            //         this.thisBucketType = '1' //内部桶
            //     } else if (item.bucketEx == 1) {
            //         this.thisBucketType = '2' //外部桶
            //     }
            // } else if (this.$route.name == 'advancedSearch') {
            //     if (item.bucketOwner == get('email')) {
            //         this.thisBucketType = '1' //内部桶
            //     } else {
            //         this.thisBucketType = '2' //外部桶
            //     }
            // }
            // this.isDetail = true
            // this.drawer = true
        },
        closeDrawer() {
            this.drawer = false
            this.isDetail = false
        },
        restore(item) {
            this.selectItem = item
            const fileName = [item.fileRoad.slice(1) + item.fileName]
            this.$refs.takebackSetting.show({bucketName: item.bucketName, fileName})
        },
        // download(item) {
        //     this.downloadFile({
        //         bucketName: item.bucketName,
        //         fileName: item.fileRoad.slice(1) + item.fileName,
        //     }).then((res) => {
        //         window.open(res.data.url, '_blank')
        //     })
        // },

        generateObjectUrl (bucketName, key) {
            let data = {
                bucketName,
                key: key,
                seconds: 900,
                versionId: null,
            }
            return this.$postJson('/file/generateObjectUrl', data)

        },



        async downloadFile(index, row) {
            // const url = this.s3.getObjectUrl({
            //     bucket: row.bucketName,
            //     key: row.key,
            //     Expires: 24 * 3600,
            //     versionId: null
            // });
            const res = await this.generateObjectUrl(row.bucket, row.name)
            this.$post(`/file/download/count?bucketName=${row.bucketName}&size=${row.size}`).then(() => {})
            let url = ''
            if (res.code === '200') {
                url = res.data
            }
            this.downloadFilesByUrls([{url, displayName: row.displayName}]);
        },
        downloadFilesByUrls(urls = []) {
            for (let j = 0; j < urls.length; j++) {
                const item = urls[j];
                // this.downloadFileDo(item.url)
                let url = item.url
                // if (this.currentItem.bucketAcl && this.currentItem.bucketAcl.indexOf('公共') > -1) {
                //     url = url.split('?')[0]
                // }
                window.open(url)
            }
        },
        editTag(item) {
            let key = this.handleFileName(item.name)[1]
            this.$refs.editTagRef.show({...item, key: item.name, bucketName: item.bucket, versionId: item.versionId || null})
        },
        recovery() {
            const filename_list = this.selectData.map((item) => `${item.bucketName}${item.fileRoad}${item.fileName}`)
            this.$refs.confirmRecoverFiles.judge(filename_list)
        },
    },
}
</script>
<style lang="scss" scoped>
.condition {
    padding: 20px;
    background: #fff;

    .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .item {
            width: 50%;
            margin-bottom: 15px;
            display: flex;

            .relation,
            .type,
            .el-date-editor {
                width: 100%;
            }

            label {
                width: 100%;
                height: 36px;
                line-height: 36px;
                padding-left: 15px;
                font-size: 14px;
                color: #606266;
                border: 1px solid #dcdfe6;
                border-radius: 4px 0 0 4px;
                box-sizing: border-box;
                display: inline-block;
            }

            .txt {
                width: 100%;
                height: 36px;
                line-height: 36px;
                color: #606266;
                text-align: center;
                display: inline-block;
            }
        }
    }

    .operate {
        //text-align: center;
        margin-top: 10px;
    }
}

.operate {
    margin-top: 32px;
    text-align: left;
}

.fileName {
    display: flex;
    align-items: center;
    color: #006bff;
    cursor: pointer;

    div {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    span {
        height: 20px;
        color: #006bff;
        background: rgba(54, 131, 220, 0.1);
        border-radius: 12px;
        display: inline-block;
        padding: 0 10px;
        vertical-align: text-bottom;
        line-height: 20px;

        i {
            margin-right: 5px;
            font-size: 10px;
            color: #006bff;
            font-weight: bold;
        }
    }
}

.status {
    color: #ccc;
    vertical-align: middle;
}
</style>
<style>
.el-input-group__prepend div.el-select .el-input__inner {
    color: #606266;
}
</style>
<style lang="scss">
.userlevelSearch {
    .el-drawer__wrapper {
        #el-drawer__title {
            margin-bottom: 0px !important;
        }
    }
}
</style>
